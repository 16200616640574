import React from 'react';
import './InfoText.css';

function InfoText() {
  return (
    <div className="info-text">
      For further information, please contact james.burke@bramptoncc.org.uk<br/><br/>
      "Brampton Youth Project" is a trading name of Brampton and Beyond Community Trust, a charitable company registered in England and Wales.<br/>
      Company Number: 07304443 | Charity Number: 1138044 | Registered Address: Brampton Community Centre, Union Lane, Brampton, CA8 1BX.
    </div>
  );
}

export default InfoText;
