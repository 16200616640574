import React from 'react';
import './Logo.css';

function Logo() {
  return (
    <div className="logo-container">
      <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" className="logo"/>
    </div>
  );
}

export default Logo;
